<template>
  <container class="artists-detail max-w-screen-md py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">edit artist</h1>

    <div class="form-container">
      <form @submit="onSubmit">
        <div class="input-wrapper">
          <label>name</label>
          <input
              v-model="nameField.value"
              type="text"
              placeholder="Artists full name"
          />
          <span>{{ nameField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>Is Hidden From Artist List</label>
          <input
            v-model="isHiddenFromArtistList.value"
            type="checkbox"
            placeholder="Is Hidden From Artist List"
          />
          <span>{{ isHiddenFromArtistList.errors[0] }}</span>
        </div>

        <div class="input-wrapper" v-if="!hasAvatar">
          <label>avatar</label>
          <ordered-upload v-model="avatarField.value"/>
          <span>{{ avatarField.errors[0] }}</span>
        </div>

        <div class="input-wrapper" v-else @click="removeAvatar">
          <label>avatar</label>
          <img :src="avatarField.value[0].url" alt="" width="100" height="100">
        </div>

        <div class="input-wrapper">
          <label>video</label>
          <input
              v-model="videoField.value"
              type="text"
              placeholder="Youtube video link"
          />
          <span>{{ videoField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>quote</label>
          <Editor v-model="quoteField.value">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <span>{{ quoteField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>bio</label>
          <Editor v-model="bioField.value">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <span>{{ bioField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>review</label>
          <Editor v-model="reviewField.value">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <span>{{ reviewField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>facebook</label>
          <input
              v-model="facebookField.value"
              type="text"
              placeholder="(optional)"
          />
          <span>{{ facebookField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>website</label>
          <input
              v-model="websiteField.value"
              type="text"
              placeholder="(optional)"
          />
          <span>{{ websiteField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>twitter</label>
          <input
              v-model="twitterField.value"
              type="text"
              placeholder="(optional)"
          />
          <span>{{ twitterField.errors[0] }}</span>
        </div>

        <div class="input-wrapper">
          <label>instagram</label>
          <input
              v-model="instagramField.value"
              type="text"
              placeholder="(optional)"
          />
          <span>{{ instagramField.errors[0] }}</span>
        </div>
        <div class="flex justify-between">
          <button type="submit" class="button primary">Submit</button>
          <button type="button" @click="deleteItem()" class="button dark">Delete</button>
        </div>
        <ConfirmDialog></ConfirmDialog>
      </form>
    </div>
  </container>
</template>

<script>
import Container from "@/components/Container.vue";
import OrderedUpload from "@/components/OrderedUpload/OrderedUpload.vue";
import Editor from "primevue/editor";
import {ArtistService} from "@/services/apiService";
import {useRouter} from "vue-router";
import useArtistInformation from "@/hooks/useArtistInformation.js";
import ConfirmDialog from 'primevue/confirmdialog';
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";

export default {
  name: "ArtistDetail",
  components: {Container, Editor, OrderedUpload, ConfirmDialog},
  async setup() {
    const router = useRouter();
    const confirm = useConfirm();
    const toast = useToast();

    const {
      nameField,
      isHiddenFromArtistList,
      avatarField,
      hasAvatar,
      videoField,
      bioField,
      quoteField,
      reviewField,
      facebookField,
      websiteField,
      twitterField,
      instagramField,
      createOrUpdateArtist,
      removeAvatar,
      setArtistInformation,
      deleteArtist,
      isNew,
    } = useArtistInformation();

    const deleteItem = () => {
      confirm.require({
        message: 'Are you sure you want to delete this artist?',
        header: 'Confirm delete',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          deleteArtist().then(isSuccessful => {
            if (isSuccessful && isSuccessful.status) {
              toast.add({severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000});
              router.push('/artists');
            } else {
              toast.add({severity: 'error', summary: 'Rejected', detail: 'Record was not deleted', life: 3000});
            }
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      });

    }

    if (!isNew) {
      const payload = await ArtistService.getArtist(router.currentRoute.value.params.id);
      setArtistInformation(payload.data);
    }

    const onSubmit = (val) => {
      createOrUpdateArtist(val)
          .catch(() => toast.add({
            severity: 'error',
            summary: 'Failed',
            detail: `Artist was not ${isNew ? 'Created' : 'Updated'}`,
            life: 3000
          }));
    }

    return {
      nameField,
      isHiddenFromArtistList,
      avatarField,
      bioField,
      hasAvatar,
      videoField,
      quoteField,
      reviewField,
      facebookField,
      websiteField,
      twitterField,
      instagramField,
      onSubmit,
      createOrUpdateArtist,
      removeAvatar,
      deleteItem,
      deleteArtist,
    };
  },
};
</script>

<style scoped>
</style>

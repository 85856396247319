import {reactive, computed} from "vue";
import {useField, useForm} from "vee-validate";
import {useRouter} from 'vue-router';
import {ArtistService} from "@/services/apiService";
import {useToast} from "primevue/usetoast";

export default function useArtistInformation() {
    const router = useRouter();
    const isNew = router.currentRoute.value.params.id === 'new';
    let artist;
    const toast = useToast();
    const form = useForm({
        initialValues: {
            name: "",
            video: "",
            avatar: [],
            quote: "",
            bio: "",
            review: "",
        },
    });

    const nameField = reactive(useField("name", "required|min:3"));
    const avatarField = reactive(useField("avatar", ""));
    const hasAvatar = computed(() => avatarField.value.length === 1);
    const videoField = reactive(useField("video"));
    const quoteField = reactive(useField("quote", ));
    const isHiddenFromArtistList = reactive(useField("is_hidden_from_artist_list", ""));
    const bioField = reactive(useField("bio", ));
    const reviewField = reactive(useField("review"));

    const facebookField = reactive(useField("facebook", ""));
    const websiteField = reactive(useField("website", ""));
    const twitterField = reactive(useField("twitter", ""));
    const instagramField = reactive(useField("instagram", ""));

    const createOrUpdateArtist = form.handleSubmit((values) => {
        const obj = {};

        values.is_hidden_from_artist_list = !!values.is_hidden_from_artist_list

        Object.entries(values)
            .forEach((entry) => {
                obj[entry[0]] = entry[1];
            });

        const socials = [];
        if (values.facebook) socials.push({
            type: 'facebook',
            url: values.facebook,
            handle: getHandle(values.facebook, 'facebook')
        });
        if (values.website) socials.push({type: 'website', url: values.website, handle: null});
        if (values.twitter) socials.push({
            type: 'twitter',
            url: values.twitter,
            handle: getHandle(values.twitter, 'twitter')
        });
        if (values.instagram) socials.push({
            type: 'instagram',
            url: values.instagram,
            handle: getHandle(values.instagram, 'instagram')
        });

        if (typeof obj.avatar != 'string' && obj.avatar[0].url) {
            obj.avatar = obj.avatar[0].url ;
        }

        obj["socials"] = socials;

        if (!obj.socials.length) {
            toast.add({
                severity: 'error',
                summary: 'Failed',
                detail: `Please add at least one socials field for the artist.`,
                life: 3000
            });
        }

        // if (isNew) {
        //     obj.avatar = obj.avatar[0].url;
        // }


        const req = isNew ?
            ArtistService.create(obj).then(() => {
            }) :
            ArtistService.update(router.currentRoute.value.params.id, obj);
        return req.then(() => {
            router.push('/artists');
            toast.add({
                severity: 'success',
                summary: 'Confirmed',
                detail: `Artist ${isNew ? 'Created' : 'Updated'}`,
                life: 3000
            })

        }).catch(() => {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Artist not saved, fill all the required fields.',
                life: 3000
            });
        });
    });

    const getHandle = (url, type) => {
        if (type === 'twitter') {
            return /(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?@?([^/?\s]*)/.exec(url)[1];
        } else if (type === 'facebook') {
            return /(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook\.com)\/([A-Za-z0-9-_.]+)/im.exec(url)[1];
        } else if (type === 'instagram') {
            return /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/im.exec(url)[1];
        }
    }

    const deleteArtist = () => {
        return ArtistService.delete(router.currentRoute.value.params.id);
    }

    const removeAvatar = () => {
        avatarField.value = [];
    };

    const setArtistInformation = (artistData) => {
        console.log(artistData)
        artist = artistData;
        nameField.value = artist.name;
        avatarField.value = artist.avatar;
        videoField.value = artist.video;
        quoteField.value = artist.quote;
        isHiddenFromArtistList.value = !!artist.is_hidden_from_artist_list;
        bioField.value = artist.bio;
        reviewField.value = artist.review;
        if (artist.socials.find(social => social.type === 'facebook')) {
            facebookField.value = artist.socials.find(social => social.type === 'facebook').url;
        }
        if (artist.socials.find(social => social.type === 'website')) {
            websiteField.value = artist.socials.find(social => social.type === 'website').url;
        }
        if (artist.socials.find(social => social.type === 'twitter')) {
            twitterField.value = artist.socials.find(social => social.type === 'twitter').url;
        }
        if (artist.socials.find(social => social.type === 'instagram')) {
            instagramField.value = artist.socials.find(social => social.type === 'instagram').url;
        }
    }

    return {
        artist,
        nameField,
        avatarField,
        bioField,
        hasAvatar,
        videoField,
        quoteField,
        isHiddenFromArtistList,
        reviewField,
        facebookField,
        websiteField,
        twitterField,
        instagramField,
        createOrUpdateArtist,
        removeAvatar,
        setArtistInformation,
        deleteArtist,
        isNew
    };
}
